import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener, OnDestroy, OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {ContextSearchStrategy} from '@app/_modules/_shared/_interfaces/search-strategy.interface';
import {debounceTime, from, Subject, takeUntil} from 'rxjs';
import {distinctUntilChanged, switchMap, tap} from 'rxjs/operators';

@Component({
  selector: 'app-modal-search',
  templateUrl: './modal-search.component.html'
})
export class ModalSearchComponent implements OnInit, OnDestroy, AfterViewInit {
  query: string = '';
  newQuery: string = '';
  private querySubject = new Subject<string>();
  private unsubscribe$ = new Subject<void>();

  activeIndex: number = 0;
  activeHandlerBlur: boolean = true;
  flagScroll: boolean = false;

  @ViewChild('inputSearch') inputElement!: ElementRef;
  @ViewChild('options') optionsList!: ElementRef;
  @Output() completed = new EventEmitter<string>();

  resultAux: any[] = [];
  result: any[] = [];

  contextSearchStrategy!: ContextSearchStrategy;

  constructor() {
  }

  ngOnInit() {
    this.querySubject.pipe(
      debounceTime(600),
      distinctUntilChanged(),
      takeUntil(this.unsubscribe$)
    ).subscribe((newQuery) => {
      this.query = newQuery.trim();
      const normalizedQuery = this.normalizeString(this.query);
      // this.result = this.filteredEntities();
      this.filteredEntities(normalizedQuery);
      this.activeIndex = 0;
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  isAfterViewInit: boolean = false;
  ngAfterViewInit() {
    Promise.resolve().then(() => this.inputElement.nativeElement.focus());
    this.isAfterViewInit = true;
  }

  handleBlur() {
    if (this.activeHandlerBlur)
      this.inputElement.nativeElement.focus();
  }

  updateQuery() {
    this.querySubject.next(this.newQuery);
  }

  firstQuery: any[] = [];
  async search(query: string) {
    this.contextSearchStrategy.searchStrategy.getFilteredData(query).pipe(
      switchMap((entities) => {
        this.resultAux = [];
        return from(entities);
      }),
      tap((entity) => {
        this.resultAux.push(entity);
        this.result = this.resultAux.slice(0, 25);
        if (query === '') {
          this.firstQuery.push(entity);
          if (this.firstQuery.length < 299) {
            this.flag = true;
          }
        }
      })
    ).subscribe();
  }

  private normalizeString(str: string): string {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase().trim();
  }

  private filterEntities(queryWords: string[], result: any[]): any[] {
    // const normalizedQuery = this.normalizeString(query);
    // console.log("normalizedQuery", normalizedQuery);
    //  const queryWords = normalizedQuery.split(/\s+/);
    // console.log("queryWords", queryWords);

    return result.filter(p => {
      const normalizedEntityName = this.normalizeString(p.entity_name);
      const normalizedEntityCode = this.normalizeString(p.entity_code);

      const normalizedEntityAttributes = normalizedEntityName + ' ' + normalizedEntityCode;
      return queryWords.every(queryWord => normalizedEntityAttributes.includes(queryWord));
    });
  }

  private filteredEntities(query: string) {
    if (query.length > 0) {
      this.filteredEntitiesWithApiFallback(query);
    } else {
      this.resultAux = this.firstQuery;
      this.result = this.resultAux.slice(0, 25);
    }
  }

  flag: boolean = false;
  private filteredEntitiesWithApiFallback(query: string) {
    const queryWords = query.split(/\s+/);

    const localFiltered = this.filterEntities(queryWords, this.resultAux);

    if (localFiltered.length > 50 || this.flag) {
      this.result = localFiltered;
      return;
    }

    const x = this.query.toUpperCase().trim();
    const y = x.split(/\s+/);

    const queryUrl= `query=${y.join('%20')}`;

    this.search(queryUrl).then(() => {
      this.result = this.filterEntities(queryWords, this.resultAux);
    });
  }

  onScroll(event: Event) {
    if (this.query.length > 0) return;

    const inputTarget = event.target as HTMLInputElement;
    const threshold = 100;
    const position = inputTarget.scrollHeight - (inputTarget.scrollTop + inputTarget.clientHeight);

    if (position < threshold) {
      const moreItems = this.result.length + 25;
      this.result = this.resultAux.slice(0, moreItems > this.resultAux.length ? this.resultAux.length : moreItems);
    }
  }

  setActiveIndex(newIndex: number, event?: MouseEvent): void {
    if (this.flagScroll && event && event.type === 'mouseover') {
      this.flagScroll = false;
      return;
    }

    this.activeIndex = newIndex;

    setTimeout(() => {
      const itemElement = this.optionsList.nativeElement.children[this.activeIndex];
      const listElement = this.optionsList.nativeElement;

      if (itemElement && listElement) {
        const itemTop = itemElement.getBoundingClientRect().top;
        const itemBottom = itemElement.getBoundingClientRect().bottom;
        const listTop = listElement.getBoundingClientRect().top;
        const listBottom = listElement.getBoundingClientRect().bottom;

        if (itemBottom > listBottom) {
          listElement.scrollTop += itemBottom - listBottom;
          this.flagScroll = true;
        } else if (itemTop < listTop) {
          listElement.scrollTop -= listTop - itemTop;
          this.flagScroll = true;
        }
      }
    });
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {

    if (event.key === 'ArrowDown') {
      this.setActiveIndex(Math.min(this.result.length - 1, this.activeIndex + 1));
      return event.preventDefault();
    }

    if (event.key === 'ArrowUp') {
      this.setActiveIndex(Math.max(0, this.activeIndex - 1));
      return event.preventDefault();
    }

    if (event.key === 'Enter' && this.activeIndex >= 0 && this.isAfterViewInit) {
      this.selectActiveIndex();
      return event.preventDefault();
    }
  }

  selectActiveIndex() {
    if (this.result.length > 0 && this.activeIndex >= 0) {
      this.completed.emit(
        this.result[this.activeIndex].entity_code
      );
    }
  }

  closeModal() {
    this.completed.emit('');
  }
}
