import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';

import * as fromApp from './_store/app.reducer';
import * as AuthActions from '@app/_modules/_auth/_store/auth.actions';
import * as WorkspaceActions from './_store/workspace/workspace.actions';
import {GlobalKeyboardShortcutsService} from '@app/_services/global-keyboard-shortcuts.service';
import {Subscription} from 'rxjs';
import {ModalSearchService} from '@app/_services/modal-search.service';
import {ProductSearchStrategyService} from '@app/_modules/_products/_services/product-search-strategy.service';
import {CustomerSearchStrategyService} from '@app/_modules/_customers/_services/customer-search-strategy.service';
import {ContextSearchStrategy} from '@app/_modules/_shared/_interfaces/search-strategy.interface';
import {GroupSearchStrategyService} from '@app/_modules/_groups/_services/group-search-strategy.service';
import {
  InventoryInboundStrategyServiceTsService
} from '@app/_modules/_inventory/_services/inventory-inbound-strategy.service.ts.service';
import {
  InventoryOutboundStrategyServiceTsService
} from '@app/_modules/_inventory/_services/inventory-outbound-strategy.service.ts.service';
import {SwUpdate} from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly workspace: string;
  private ksSub!: Subscription;

  constructor(
    private store: Store<fromApp.AppState>,
    private ksService: GlobalKeyboardShortcutsService,
    private modalSearchService: ModalSearchService,
    private productSearchStrategy: ProductSearchStrategyService,
    private customerSearchStrategy: CustomerSearchStrategyService,
    private groupSearchStrategy: GroupSearchStrategyService,
    private inventoryInboundSearchStrategy: InventoryInboundStrategyServiceTsService,
    private inventoryOutboundSearchStrategy: InventoryOutboundStrategyServiceTsService,
    private swUpdate: SwUpdate
  ) {
    console.log('CIFA version 0.9.3');

    const hostname = window.location.hostname;

    let domain = '';
    const split = hostname.split('.');

    if (split[split.length - 1] === 'localhost') {
      domain = 'localhost';
      split.pop();
    }

    if (domain === '' && split.length > 1) {
      split.pop();
      split.pop();
    }

    const subdomain = split.join('');
    this.workspace = subdomain.length < 4 ? 'main' : subdomain;
  }

  ngOnInit(): void {
    // if (this.swUpdate.isEnabled) {
    //   this.swUpdate.versionUpdates.subscribe(event => {
    //     if (event.type === 'VERSION_READY') {
    //       const userConfirmed = confirm(
    //         'Hay una nueva versión disponible. ¿Deseas actualizar ahora?'
    //       );
    //       if (userConfirmed) {
    //         this.updateApp();
    //       }
    //     }
    //   });
    // }

    this.store.dispatch(
      new WorkspaceActions.SetWorkspace(this.workspace)
    );

    this.store.dispatch(
      new AuthActions.AutoLogin()
    );

    this.ksSub = this.ksService.watchKeyboardShortcuts()
      .subscribe(event => {
        const inputTarget = event.target instanceof HTMLInputElement && event.target.hasAttribute('data-target-cmd-k')
          ? (event.target as HTMLInputElement)
          : null;

        const repository = inputTarget ? inputTarget.getAttribute('data-target-cmd-k') : null;

        let contextSearchStrategy: ContextSearchStrategy | null = null;

        if (repository === 'customers') {
          contextSearchStrategy = new ContextSearchStrategy(this.customerSearchStrategy);
        }

        if (repository === 'groups') {
          contextSearchStrategy = new ContextSearchStrategy(this.groupSearchStrategy);
        }

        if (repository === 'products') {
          contextSearchStrategy = new ContextSearchStrategy(this.productSearchStrategy);
        }

        if (repository === 'inventory-inbound') {
          contextSearchStrategy = new ContextSearchStrategy(this.inventoryInboundSearchStrategy);
        }

        if (repository === 'inventory-outbound') {
          contextSearchStrategy = new ContextSearchStrategy(this.inventoryOutboundSearchStrategy);
        }

        switch (event.key) {
          case 'k':
            if (((event.metaKey || event.ctrlKey) && inputTarget) || this.modalSearchService.isModalOpen) {
              if (contextSearchStrategy)
                this.modalSearchService.openModal(inputTarget!, contextSearchStrategy!);
              else
                this.modalSearchService.closeModal();
            }
            break;
          case 'Escape':
            this.modalSearchService.closeModal();
            break;
        }
      });
  }

  updateApp(): void {
    this.swUpdate.activateUpdate().then(() => {
      document.location.reload();
    });
  }

  ngOnDestroy(): void {
    if (this.ksSub) {
      this.ksSub.unsubscribe();
    }
  }
}
