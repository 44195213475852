import { Injectable } from '@angular/core';
import {SearchStrategy} from '@app/_modules/_shared/_interfaces/search-strategy.interface';
import {Observable, Subscription, take} from 'rxjs';
import {ApiInventoryOutboundService} from '@app/_modules/_inventory/_services/api-inventory-outbound.service';
import {IOutbound, ISearchInventoryOutbound} from '@app/_modules/_inventory/_models/outbound.interface';
import {map, tap} from 'rxjs/operators';
import {SettingsPosService} from '@app/_modules/_settings/_services/settings-pos.service';

@Injectable({
  providedIn: 'root'
})
export class InventoryOutboundStrategyServiceTsService implements SearchStrategy<ISearchInventoryOutbound> {

  columns = [
    {width: 'w-36', textAlign: 'text-left', label: '# Egreso / Motivo', key: 'entity_code'},
    {width: 'w-28', textAlign: 'text-left', label: 'fecha', key: 'inbound_date'}
  ]

  tax_identification = '';
  establishment = '';

  private subs = new Subscription();

  constructor(
    private apiInventoryOutboundService: ApiInventoryOutboundService,
    private settingPosService: SettingsPosService
  ) {

    this.subs.add(
      this.settingPosService.establecimiento$.subscribe(
        establecimiento => {
          if (!establecimiento)
            return;

          this.tax_identification = establecimiento.ruc;
          this.establishment = establecimiento.establecimiento;
        }
      )
    );
  }

  cleanup(): void {
    this.subs.unsubscribe(); // Cierra todas las suscripciones
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getFilteredData(query: string): Observable<ISearchInventoryOutbound[]> {
    return this.apiInventoryOutboundService.getOutboundList(this.tax_identification, this.establishment).pipe(
      map((inventoryOutbound: IOutbound[]) =>
        inventoryOutbound.map(inventoryOutbound => {
          return {
            entity_code: inventoryOutbound.outbound_id.toString(),
            entity_name: inventoryOutbound.reason,
            outbound_date: inventoryOutbound.outbound_date.slice(0,10),
          };
        })
      )
    );
  }
}
