<div class="relative z-50" role="dialog" aria-modal="true" xmlns="http://www.w3.org/1999/html">
  <!--
    Background backdrop, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0"
      To: "opacity-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100"
      To: "opacity-0"
  -->
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

  <div class="fixed inset-0 z-50 w-screen overflow-y-auto modal-search--padding">
    <!--
      Command palette, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 scale-95"
        To: "opacity-100 scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 scale-100"
        To: "opacity-0 scale-95"
    -->
    <div class="mx-auto max-w-2xl transform rounded-xl bg-white p-2 shadow-2xl ring-1 ring-black ring-opacity-5 transition-all flex flex-col  modal-search--height">
      <div class="flex-none h-fit max-h-fit">
        <input #inputSearch type="text"
               class="w-full rounded-md border-0 bg-gray-100 px-4 py-2.5 text-gray-900 focus:ring-0 sm:text-sm"
               placeholder="Buscar..." role="combobox" aria-expanded="false" aria-controls="options"
               [(ngModel)]="newQuery"
               (ngModelChange)="updateQuery()"
               (blur)="handleBlur()">
        <div class="flex px-4 py-2 bg-white text-sm text-gray-400">
          <div class="flex-none w-7 text-xs">#</div>
          <div *ngFor="let column of this.contextSearchStrategy.searchStrategy.columns"
               [class]="['flex-none',column.width, column.textAlign]">
            {{ column.label }}
          </div>
        </div>
      </div>

      <div class="grow overflow-y-auto" (scroll)="onScroll($event)">
        <ul #options
            class="py-2 text-sm text-gray-800 divide-y divide-gray-20 modal-search__striped-list scroll-py-2"
            id="options"
            role="listbox">
          <li *ngFor="let item of result; let i = index"
              [ngClass]="{'!bg-indigo-600 !text-white padre': i === activeIndex}" role="option" tabindex="-1"
              (mouseover)="setActiveIndex(i, $event)"
              (click)="setActiveIndex(i, $event)"
              (dblclick)="selectActiveIndex()">
            <div class="flex flex-nowrap px-4 items-center">
              <div class="flex-none w-7 text-xs">{{ i + 1 }}</div>
              <div>
                <div class="flex text-sm">
                  <div *ngFor="let column of contextSearchStrategy.searchStrategy.columns"
                       [class]="['flex-none',column.width, column.textAlign]">
                    {{ item[column.key] }}
                  </div>
                </div>
<!--                <div class="flex text-sm">-->
<!--                  <div class="flex-nowrap">-->
<!--                    <span>{{ item.entity_name }}</span>-->
<!--                  </div>-->
<!--                </div>-->
                <div class="flex text-sm" [innerHTML]="item.entity_name"></div>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div *ngIf="result.length === 0" class="flex-none h-fit">
        <div class="px-4 py-14 text-center sm:px-14">
          <svg class="mx-auto h-6 w-6 text-gray-400" fill="none"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z" />
          </svg>

          <p class="mt-4 text-sm text-gray-900">No hay resultados para "{{ query }}"</p>
        </div>
      </div>

      <div class="flex-none h-fit">
        <div class="hidden sm:flex flex-wrap items-center bg-gray-50 px-4 py-2.5 text-xs text-gray-700">
          Presiona
          <kbd class="mx-1 flex h-5 w-7 items-center justify-center rounded border border-gray-400 bg-white font-sans text-gray-900 sm:mx-2">
            esc
          </kbd>
          <span>o</span>
          <kbd class="mx-1 flex h-5 w-7 items-center justify-center rounded border border-gray-400 bg-white font-sans text-gray-900 sm:mx-2">
            ⌘K
          </kbd>
          para salir /
          <kbd class="mx-1 flex h-5 w-9 items-center justify-center rounded border border-gray-400 bg-white font-sans text-gray-900 sm:mx-2">
            Enter
          </kbd>
          o (doble clic) para seleccionar
        </div>
        <div class="sm:hidden flex flex-wrap items-center bg-gray-50 px-4 py-2.5 text-xs text-gray-700">
          <button (click)="closeModal()" class="ml-2 btn-info">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</div>
