import {SearchStrategy} from '@app/_modules/_shared/_interfaces/search-strategy.interface';
import {IProduct, ISearchProduct, ISimpleProduct, Product} from '@app/_modules/_products/_models/product.interface';
import {ApiProductService} from '@app/_modules/_products/_services/api-product.service';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {RoundingService} from '@app/_services/rounding.service';

@Injectable(
  {providedIn: 'root'}
)
export class ProductSearchStrategyService implements SearchStrategy<ISearchProduct> {

  columns = [
    {width: 'w-36', textAlign: 'text-left', label: 'Código / Nombre', key: 'entity_code'},
    {width: 'w-14', textAlign: 'text-right', label: 'Cant.', key: 'stock'},
    {width: 'w-14', textAlign: 'text-right', label: 'PVP 1', key: 'selling_price_1'},
    {width: 'w-14', textAlign: 'text-right', label: 'PVP 2', key: 'selling_price_2'},
    {width: 'w-14', textAlign: 'text-right', label: 'PVP 3', key: 'selling_price_3'},
    {width: 'w-14', textAlign: 'text-right', label: 'Marca', key: 'brand'},
    // {width: 'w-14', textAlign: 'text-right', label: 'Procedencia', key: 'selling_price_3'},
    // {width: 'w-14', textAlign: 'text-right', label: 'Ubicación', key: 'selling_price_3'},
    {width: 'w-16', textAlign: 'text-right', label: 'Costo Factura', key: 'purchase_price'},
    {width: 'w-16', textAlign: 'text-right', label: 'Costo Final', key: 'cost_av'},
    {width: 'w-16', textAlign: 'text-right', label: 'Grupo', key: 'group_code'}
  ];

  constructor(
    private apiProductService: ApiProductService,
    private roundingService: RoundingService,) {
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getFilteredData(query: string): Observable<ISearchProduct[]> {
    return this.apiProductService.getProductList(query).pipe(
      map((products: IProduct[]) =>
        products.map(product => {
          const p = new Product(product);

          const stock = this.roundingService.roundTo2(+product.stock);
          const hasIvaTax = product.taxes.iva_tax?.cod === '4';
          const firstSellingPrice_1 = Number(p.getFinalPrice(1)).toFixed(2);
          const firstSellingPrice_2 = Number(p.getFinalPrice(2)).toFixed(2);
          const firstSellingPrice_3 = Number(p.getFinalPrice(3)).toFixed(2);

          let class_style = stock > 0 ? 'font-bold text-slate-600 hijo' : '';
          let product_name = '<div class="grow "><span class="'+class_style+'">'+product.name+'</span></div><div class="flex-none w-40 truncate">Grupo: '+product.group_name+'</div>';

          const costo_factura = this.roundingService.roundTo2(+product.purchase_price);
          const costo_final = this.roundingService.roundTo2(+product.average_cost);

          const marca = product.extended_data?.brand || '';

          return {
            entity_code: product.code,
            entity_name: product_name,
            stock: this.roundingService.roundTo2(+product.stock),
            iva_tax: hasIvaTax ? 'S' : 'N',
            selling_price_1: firstSellingPrice_1,
            selling_price_2: firstSellingPrice_2,
            selling_price_3: firstSellingPrice_3,
            purchase_price: costo_factura,
            cost_av: costo_final,
            group_code: product.group_code,
            brand: marca
          };
        })
      )
    );
  }
}
