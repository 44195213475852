import {ISearchResult} from '@app/_modules/_shared/_interfaces/search-strategy.interface';

export interface IExtendedDataProduct {
  unit: string;
  location: string;
  brand: string;
  origin: string;
}

export interface IProductComponent {
  product_id: number;
  product_code: string;
  product_name: string;
  quantity: number;
}

export interface PricesEntity {
  price_type: number;
  base_price: number;
  final_price: number;
}

export interface IIVATaxEntity {
  cod: string;
  percentage: number;
}

export interface IICETaxEntity {
  ice_cod: string;
  ice_val: string;
}

export interface ITaxes {
  iva_tax?: IIVATaxEntity;
  ice_tax?: IICETaxEntity;
}

export interface ISimpleProduct {
  product_id: number;
  code: string;
  name: string;
  stock: number;
  taxes: ITaxes;
  selling_prices: PricesEntity[];
  average_cost: number;
  purchase_price: number;
}

export interface IProduct extends ISimpleProduct{
  description: string;
  notes: string;
  unit: string;
  type_product: string;
  list_for_sale: boolean;
  count_stock: boolean;
  initial_date: string;
  initial_stock: number;
  minimum_stock: number;
  stock: number;
  initial_cost: number;

  group_id: number;
  group_code: string;
  group_name?: string;

  components: IProductComponent[];
  extended_data?: IExtendedDataProduct;
}

export class Product {
  product_id: number;
  code: string;
  name: string;
  stock: number;
  taxes: ITaxes;
  selling_prices: PricesEntity[];
  purchase_price: number;

  description: string;
  unit: string;
  minimum_stock: number;

  extended_data?: IExtendedDataProduct;

  constructor(iproduct: IProduct) {
    this.product_id = iproduct.product_id;
    this.code = iproduct.code;
    this.name = iproduct.name;
    this.stock = iproduct.stock;
    this.taxes = iproduct.taxes;
    this.selling_prices = iproduct.selling_prices;
    this.purchase_price = iproduct.purchase_price;
    this.description = iproduct.description;
    this.unit = iproduct.unit;
    this.minimum_stock = iproduct.minimum_stock;
  }

  getFinalPrice(type: number): number {
    return this.selling_prices.find((price: PricesEntity) => price.price_type === type)?.final_price || 0;
  }
}

export interface ISearchProduct  extends ISearchResult {
  stock: number;
  iva_tax: string;
  selling_price_1: string | number
  selling_price_2: string | number
  selling_price_3: string | number
  purchase_price: number;
  cost_av: number;
  group_code: string;
  brand: string;
}
